<template>
	<div class="mgrupajeequipamientoFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mgrupajeequipamiento.idgruequi')" :value="getIdgruequiValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mgrupajeequipamiento.codgruequedi')" :value="getCodgruequediValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mgrupajeequipamiento.name')" :value="getNameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mgrupajeequipamiento-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mgrupajeequipamiento'
		};
	},
	computed: {
		getIdgruequiValue() {
			return this.model && this.model.idgruequi ? this.model.idgruequi : '-';
		},
		getCodgruequediValue() {
			return this.model && this.model.codgruequedi ? this.model.codgruequedi : '-';
		},
		getNameValue() {
			return this.model && this.model.name ? this.model.name : '-';
		},
	}
}
</script>
